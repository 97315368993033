.mainDesign {
  z-index: -1000;
}

.svg-deco1 {
  opacity: 0.2;
}

.deco1 {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1;
  width: 30vh;
}
.deco2 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  width: 30vh;
  transform: rotate(180deg)
}

.deco3 {
  position: absolute;
  left: 0px;
  bottom: -30vh;
  z-index: -1;
  width: 30vh;
  transform: rotate(270deg)
}

.deco4 {
  position: absolute;
  right: 0px;
  bottom: -110vh;
  z-index: -1;
  width: 30vh;
  transform: rotate(90deg)
}

.deco5 {
  position: absolute;
  right: 0px;
  bottom: -140vh;
  z-index: -1;
  width: 30vh;
  /* transform: rotate(0deg) */
}

.deco6 {
  position: absolute;
  left: 0px;
  bottom: -220vh;
  z-index: -1;
  width: 30vh;
  transform: rotate(180deg)
}

.deco7 {
  position: absolute;
  left: 0px;
  bottom: -250vh;
  z-index: -1;
  width: 30vh;
  transform: rotate(270deg)
}

.deco8 {
  position: absolute;
  right: 0px;
  bottom: -330vh;
  z-index: -1;
  width: 30vh;
  transform: rotate(90deg)
}

.deco9 {
  position: absolute;
  right: 0px;
  bottom: -360vh;
  z-index: -1;
  width: 30vh;
  /* transform: rotate(0deg) */
}