/* Section5.css */

.section-5 {
  margin-top: 20vh;
  border-radius: 10px;
  padding: 50px 20px;
  background-color: var(--light-blueA); /* Use your background color variable */
  color: white;
  text-align: center;
}

.section-5 h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
  color: var(--accent-blue); /* Use your accent color variable */
}

.section-5 p {
  margin-bottom: 30px;
  font-size: 1.2em;
}

#contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

#contact-form textarea {
  resize: none; /* Disable resizing */
  height: 150px; /* Fixed height */
}

.char-count {
  align-self: flex-end; /* Align to the right */
  font-size: 0.9em;
  color: var(--accent-blue); /* Use your accent color variable */
  margin-bottom: 15px;
}

#contact-form input,
#contact-form textarea {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--light-blue); /* Use your light-blue color variable */
  border-radius: 5px;
  font-size: 18px;
  z-index: 1;
}

#contact-form button {
  background-color: var(--accent-inv); /* Use your accent color variable */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

#contact-form button:hover {
  background-color: var(--inv-light); /* Use your light-blue color variable */
}

#contact-form span {
  margin-top: 20px;
  font-size: 1em;
  color: var(--accent-blue); /* Use your accent color variable */
  visibility: hidden;
}

#contact-form span.visible {
  visibility: visible;
}

#contact-form span.error {
  color: var(--accent-inv);
  visibility: visible;
}

.recaptcha-container {
  margin: 10px;
}