@import "./colors.css";



.footer {
    margin-top: 20px;
    background-color: var(--background);
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    box-shadow: 0px -2px 8px #40b2e666;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}

.right-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
}

.copyright {
    /* background-color: magenta; */
    text-align: left;
}

.copyright a {
    color: var(--accent-blue);
    text-decoration: none;
    
}

.github-link {
   margin-top: 40px;
}

.github-link a{
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}

.github-link a:hover {
    color: rgb(98, 0, 255); /* Optional: underline on hover */
  }

.footer-link {
    font-size: 14px;
    color: #f9f9f9;
    margin: 5px 0;
    text-decoration: none;
}

.footer-image {
    width: 30px;
}