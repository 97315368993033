/* SECTION 4 */
@import url('../colors.css');

.section-4 {
  margin-top: 23vh;
  padding: 20px;
  border-radius: 10px;
  background: var(--light-blueA); /* Adjust background to match overall theme */
  text-align: center;
}

.section-4 h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.section-4 p {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.packages {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
  justify-content: center;
  
}

.package {
  padding: 20px;
  border-radius: 10px;
  width: 20%; /* Adjust to fit two cards per row */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.package h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.package hr {
  border: 0;
  height: 1px;
  background: #fff;
  margin-bottom: 20px;
}

.package p {
  text-align: left;
}

.get-started {
  margin-top: 20px;
  background: var(--accent-inv);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.get-started:hover {
  background: var(--inv-light);
}

@media (max-width: 768px) {
  .package {
    width: 100%;
  }
  
}