/* WhatsAppBtn.css */

.whatsapp-button {
  position: fixed;
  bottom: 180px;
  right: 20px;
  /* background-color: var(--accent-blue); */
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, width 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: nowrap;
  overflow: hidden;
}

.whatsapp-button:hover {
  background-color: var(--light-blueA);
  width: 250px;
  animation: expand 0.3s ease forwards;
}

.whatsapp-icon {
  width: 100px;
}

@keyframes expand {
  from {
    width: 100px;
  }

  to {
    width: 250px;
  }
}

.whatsapp-text {
  display: none;
  margin-left: 8px;
}

.whatsapp-button:hover .whatsapp-text {
  display: inline;
}

@media (max-width: 768px) {
  .whatsapp-button {
    position: fixed;
    top: 0px;
    right: 10px;
    height: 100px;
    overflow: hidden;
    background: transparent;
  }
  .whatsapp-icon {
    width: 100px;
  }
  .whatsapp-button:hover .whatsapp-text{
    display: none;
  }
  .whatsapp-button:hover {
    width: 100px;
    animation: none;
  }
}