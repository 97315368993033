/* SECTION 3 */

.section-3 {
  margin-top: 25vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  
}
.img-containter3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
 }
.text-container3 {
  flex: 1;
 }
 
 .watermark {
  max-width: 65%;
  height: auto;
 }

 @media (max-width: 768px) {

  .watermark {
    max-width: 100%;
    height: auto;
   }

 }
