@import url('./colors.css');

body {
  margin: 0 calc(10vw + 10px);
  padding: 0;
  min-height: 100vh;
  background: radial-gradient(circle at top left, #003756 0%, #010510 70%);
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 200vw;
  font-family: 'Arial', sans-serif;
  color: #fff;
}


body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}


/* TOP BAR */

#anchorSection4 {
  /* background-color: magenta; */
  visibility: hidden;
  position: relative;
  top: 180px;
}

#anchorSection5 {
  /* background-color: magenta; */
  visibility: hidden;
  position:relative;
  top: 150px;

}


.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  
}

.logo {
  width: 50vh;
  height: auto;
}

.menu {
  display: flex;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.menu ul li {
  display: inline;
}

.menu ul li a {
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.menu ul li a:hover {
  background-color: var(--accent-inv);
}

/* bgr-menu Menu */
.bgr-menu {
  display: none;
  cursor: pointer;
  font-size: 36px;
}

/* Responsive Styles */
@media (max-width: 1085px) {



  body {
    margin: 10px;
  }

  .logo {
    display: none;
  }

  .top-bar {
    position:initial;
  }

  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, #010510 50%, rgba(1, 5, 16, 0.8));
    text-align: center;
    padding-top: 100px;
    overflow: hidden;
    /* Hide overflowing content */
    animation: slideIn 0.5s forwards;
  }

  .menu.open {
    display: flex;
  }

  @keyframes slideIn {
    from {
      height: 0;
      /* Start with height 0 */
    }

    to {
      height: 100%;
      /* Expand to full height */
    }
  }


  .menu ul {
    flex-direction: column;
    gap: 30px;
  }

  .menu ul li {
    margin: 10px 0;
    font-size: 30px;
  }

  .bgr-menu.open {
    color: var(--accent-inv)
  }

  .bgr-menu {
    display: block;
    z-index: 1;
  }


}