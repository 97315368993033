/* SECTION 2 */

@import url('../colors.css');

.section-2 {
  margin-top: 20vh;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.left-part {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
  justify-content: left;
  
}

.card {
  background: var(--light-blueA);
  padding: 20px;
  border-radius: 10px;
  width: 30%; /* Adjust to fit two cards per row */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}





.icon-img {
  max-width: 60%;
  height: auto;
  margin-bottom: 10px;

}

.icon-diag {
  display: flexbox;
}

.right-part {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}

.learn-more-button {
  background: var(--accent-inv);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  width: 50%;
  margin: 10px auto;
  font-weight: bold;
}

.learn-more-button:hover {
  background: var(--inv-light);
}

@media (max-width: 768px) {
  .section-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card {

    width: 45%; /* Adjust to fit two cards per row */
  }

  .card:last-child {
    /* Center the last card */
    margin-left: auto;
    margin-right: auto;
  }

  
}