/* SECTION 1 */

.section-1 {
  margin-top: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    /* background: magenta; */
    
  }
  .text-container1 {
    flex: 1;
    font-size: 30px;
   }
   .img-containter1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .img1 {
    max-width: 80%;
    height: auto;
   }

   @media (max-width: 768px) {

    .section-1 {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .img-containter1 {
      order: 1;
    }
  
    .text-container1 {
      order: 2;
      text-align: center;
      width: 100%;
      /* font-size: 30px; */
    }
    /* .text-container1 h1 {
      display: none;
    } */
   }